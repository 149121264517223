.root {
  max-width: 28.75%;
  padding: 3rem 2.25rem;
  margin-inline: auto;
  text-align: center;
  background: white;
  border-radius: 1rem;
}
.root[data-shadow] {
  box-shadow: 0px 0px 1.5rem rgba(0, 0, 0, 0.15);
}
.root > :not(:first-child) {
  margin-block-start: 1.6rem;
}
.title {
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1.33;
}
.footer {
  font-size: 0.7rem;
  color: var(--text-color-tip);
}

@media (max-width: 768px) {
  .root {
    max-width: revert;
    border-radius: revert;
  }
}
